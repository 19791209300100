import logo from './logo.svg';
import './App.css';
import {useEffect, useReducer, useRef, useState} from "react";

function useInput(initialValue) {
    const [value, setValue] = useState(initialValue);
    return [
        {
            value,
            onChange: (e) => setValue(e.target.value)
        },
        () => setValue(initialValue)
    ]
}

function App() {
    const [emotion, setEmotion] = useState("happy")
    const [secondary_emotion, setSecondary_emotion] = useState("tired")
    // console.log(emotion)
    useEffect(() => {
        console.log(`It's ${emotion} right now`);
    }, [emotion, secondary_emotion]);
    const [checked, setChecked] = useReducer(checked => !checked, false);
    const txtTitle = useRef()
    console.log(txtTitle)
    const hexColor = useRef()
    const submit = (e) => {
        e.preventDefault();
        const title = txtTitle.current.value;
        const color = hexColor.current.value;
        alert(`${title}, ${color}`)
        txtTitle.current.value = "";
        hexColor.current.value = "";
    }
    const [title2, setTitle2] = useState("")
    const [color2, setColor2] = useState("#000000")
    const submit2 = (e) => {
        e.preventDefault();
        alert(`${title2}, ${color2}`)
        setTitle2("")
        setColor2("#000000")
    }
    const [titleProps, resetTitle] = useInput("")
    const [colorProps, resetColor] = useInput("#000000")
    const submit3 = (e) => {
        e.preventDefault();
        alert(`${titleProps.value}, ${colorProps.value}`)
        resetTitle()
        resetColor()
    }

    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo"/>
                <form onSubmit={submit3}>
                    <input
                        {...titleProps}
                        type="text"
                        placeholder="with useInput custom"
                    />
                    <input
                        {...colorProps}
                        type="text"
                        type="color"
                    />
                    <button>ADD</button>
                </form>
                <form onSubmit={submit2}>
                    <input value={title2}
                           onChange={(event) => setTitle2(event.target.value)}
                           type="text"
                           placeholder="with useState"
                    />
                    <input type="text" value={color2} type="color" onChange={event => setColor2(event.target.value)}/>
                    <button>ADD</button>
                </form>

                <label>Regi resz</label>

                <form onSubmit={submit}>
                    <input ref={txtTitle}
                           type="text"
                           placeholder="color title.."
                    />
                    <input type="text" ref={hexColor} type="color"/>
                    <button>ADD</button>
                </form>

                <input type="checkbox" value={checked} onChange={setChecked}/>
                <label>{checked ? "Yes" : "No"}</label>

                <p>
                    Hello, currently we are {emotion}
                </p>
                <button onClick={() => setEmotion("sad")}>Sad</button>
                <button onClick={() => setEmotion("happy")}>Happy</button>
                <p>
                    The secondary emotion is {secondary_emotion}
                </p>
                <button onClick={() => setSecondary_emotion("rested")}>Rested</button>
                <button onClick={() => setSecondary_emotion("tired")}>Tired</button>

            </header>
        </div>
    );
}

export default App;
